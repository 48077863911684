<template>
<div class="mainBidInfo">
    <table-tab :TabData="navData" @tabSwitch="onSwitch" defaultData="bidNotice"></table-tab>
    <bid-info v-if="showTab=='bidNotice'" :dataFormat="dataFormat" :formData="formData" class="content bascform"></bid-info>
    <evaluation-criteria :dataFormat="dataFormat" v-if="showTab=='scoring'" :formData="formData" class="content tableform"></evaluation-criteria>
    <bid-evaluation-team v-if="showTab=='bidNoticeTeam'" :dataFormat="dataFormat" :formData="formData" class="content tableform"></bid-evaluation-team>
    <inviting-suppliers v-if="showTab=='Supplier'" :dataFormat="dataFormat" :formData="formData" class="content tableform"></inviting-suppliers>
</div>
</template>

<script>
import { navData } from './js/config.js'
import Component from '@/assets/js/components.js'
export default {
  name: 'BidAllInfo',
  components: Component.components,
  props: ['formData'],
  data: function () {
    return {
      navData: navData,
      showTab: 'bidNotice',
      dataFormat: { formDisabled: true }
    }
  },
  methods: {
    onSwitch: function (data) {
      if (data.props.label === '招标信息') {
        this.showTab = 'bidNotice'
      }
      if (data.props.label === '评标标准') {
        this.showTab = 'scoring'
      }
      if (data.props.label === '评标小组') {
        this.showTab = 'bidNoticeTeam'
      }
      if (data.props.label === '受邀供应商') {
        this.showTab = 'Supplier'
      }
    }
  }
}
</script>

<style scoped lang="scss">
 @import '@/assets/css/elForm.scss';
 .mainBidInfo{
     margin-top: 20px;
     :deep(.is-disabled){
         .el-input__prefix {
             display: none;
         }
         .el-input__suffix {
             display: none;
         }
     }
 }
</style>
