export var bidFormdata = {
  bidCode: '',
  bidEndDate: '',
  bidEndTime: '',
  bidEvaluationEndTime: '',
  bidEvaluationStartTime: '',
  titleList: [],
  bidExpertList: [
    {
      bidExpertMessageList: [
        {
          bidExpertId: '',
          createDate: '',
          createUserId: '',
          id: '',
          modifyDate: '',
          realName: '',
          username: ''
        }
      ],
      bidId: '',
      createDate: '',
      createUserId: '',
      deptCode: '',
      deptName: '',
      deptWeight: 0,
      id: '',
      modifyDate: ''
    }
  ],
  bidFileList: [
  ],
  bidMaterialList: [
    {
      bidId: '',
      createDate: '',
      createUserId: '',
      deliveryCycle: 0,
      id: '',
      materialCode: '',
      materialDesc: '',
      materialNum: 0,
      modifyDate: '',
      unit: ''
    }
  ],
  bidMaterialMap: {},
  bidMaterialPriceMap: {},
  bidMinDesc: '',
  bidMinPrice: 0,
  bidScoringList: [
    {
      bidId: '',
      bidScoringMes: '',
      createDate: '',
      createUserId: '',
      id: '',
      modifyDate: '',
      score: 0,
      serialNumber: 0,
      standard: ''
    }
  ],
  bidStartDate: '',
  bidStartTime: '',
  bidSupplierList: [
    {
      bidId: '',
      code: '',
      createDate: '',
      createUserId: '',
      id: '',
      isAccept: 0,
      modifyDate: '',
      name: '',
      paymentDays: '',
      serialNumber: 0,
      srmFileList: [
        {
          createDate: '',
          createUserId: '',
          fileSize: 0,
          groupId: '',
          id: '',
          modifyDate: '',
          name: '',
          url: ''
        }
      ],
      tender: ''
    }
  ],
  businessScope: '',
  companyCode: '',
  createDate: '',
  createUserId: '',
  createUserName: '',
  evaluationBidCode: '',
  id: '',
  itemName: '',
  modifyDate: '',
  paymentDays: '',
  quoteList: [
    {}
  ],
  releaseEndTime: '',
  releaseStartTime: '',
  status: 0
}

export var SearchList = [
  { labe: '供应商名称', code: 'name', type: 'input' },
  { labe: '供应商编码', code: 'erpCode', type: 'input' }
]
export var SearchData = {
  name: '',
  erpCode: ''
}

export var navData = [{
  label: '招标信息',
  name: 'bidNotice',
  id: 1
}, {
  label: '评标标准',
  name: 'scoring',
  id: 2
}, {
  label: '评标小组',
  name: 'bidNoticeTeam',
  id: 1
}, {
  label: '受邀供应商',
  name: 'Supplier',
  id: 2
}]
